import React, { useEffect, useState } from 'react'
// import { TGetVenueOptions, getVenueMaker, showVenue } from '@mappedin/mappedin-js'
import '@mappedin/mappedin-js/lib/index.css'
import { TCameraTarget, getMapData, show3dMap } from '@mappedin/mappedin-js'
import { PRIMARY_COLOR } from 'lib/constants'
import { AlertSession, Beacon } from 'lib/types'
import { useMappedInContext } from 'contexts/MappedInProvider'
import { useGlobal } from 'contexts/GlobalProvider'

// Temporary - define beacon coordinate maps
type Beacon_Coords = {
  [key: string]: {
    coords: Number[]
    floorNum: Number
  }
}
const beacon_coords: Beacon_Coords = {
  'F8:35:CC:74:8E:A6': { coords: [-105.13356570731779, 40.552194279240304], floorNum: 1 },
  'C2:B9:06:08:BC:19': { coords: [-105.13364070731896, 40.55217206063131], floorNum: 1 },
  'E2:ED:25:FA:15:1C': { coords: [-105.13373358989547, 40.552189520388055], floorNum: 1 },
  'F0:EA:B1:20:BB:32': { coords: [-105.1336358764969, 40.55224813906885], floorNum: 1 },
  'E8:04:56:65:7D:45': { coords: [-105.13356822247897, 40.552236636897334], floorNum: 1 },
  'E0:96:0F:B5:F2:35': { coords: [-105.13357731571347, 40.55218541414222], floorNum: 0 },
  'C1:26:54:27:B4:5C': { coords: [-105.13368749327725, 40.552245398136364], floorNum: 0 },
  'E4:B2:C7:BF:1F:E9': { coords: [-105.13373154811791, 40.55221302597942], floorNum: 0 },
  'F4:B4:67:52:FB:63': { coords: [-105.13372419080135, 40.552196390693744], floorNum: 0 },
  'C6:FD:10:8C:C1:13': { coords: [-105.13362105816623, 40.552235560498374], floorNum: 0 },
  'DD:C4:5B:48:85:F7': { coords: [-105.13357035649099, 40.552125146794], floorNum: 2 },
  'C4:70:B4:51:34:CA': { coords: [-105.1335606939494, 40.552179124132714], floorNum: 2 },
  'DC:33:C8:7B:FC:F4': { coords: [-105.13360107463569, 40.55218458237247], floorNum: 2 },
  'EC:D8:35:36:BB:A0': { coords: [-105.13361460990245, 40.5522279467806], floorNum: 2 },
  // Riley's beacons
  'D0:22:9F:77:BA:B5': { coords: [-122.31969118187307, 47.62215640493455], floorNum: 2 },
  'C5:D8:DC:8A:1D:C6': { coords: [-122.31964385086388, 47.62208618404055], floorNum: 2 },
  'E4:CE:66:9B:AC:7E': { coords: [-122.31975642720317, 47.62208646444484], floorNum: 2 },
  'F2:38:34:C0:ED:55': { coords: [-122.31974506042332, 47.62215538268214], floorNum: 2 },
  'F5:80:15:C1:BE:AD': { coords: [-122.31965023273585, 47.622163849358444], floorNum: 1 },
  'F4:B4:4F:6B:5E:9D': { coords: [-122.31974773470367, 47.62208853749835], floorNum: 1 },
  'F0:36:F2:8E:7C:80': { coords: [-122.31964053678112, 47.62208394016334], floorNum: 1 },
}

// floor mapping: elevationIdx: floorId
const jeffFloors: Record<number, string> = {
  0: 'm_c31482a9948cc422',
  1: 'm_18663e4d9e0bff84',
  2: 'm_f146c13c397b6c38',
}

const rileysFloors: Record<number, string> = {
  0: 'm_26b51add82530c40',
  1: 'm_dfb600e42550c21a',
  2: 'm_3b92387c7206898f',
}

function preProcess(beacons: any) {
  // const beacons = alertEvent.attributes.beacons

  const processed = beacons.map((b: any): any => {
    if (!(b.beaconId in beacon_coords)) return {}
    // console.log({
    //   ...b,
    //   long: beacon_coords[b.beaconId].coords[0],
    //   lat: beacon_coords[b.beaconId].coords[1],
    //   alt: beacon_coords[b.beaconId].alt,
    // })
    return {
      ...b,
      long: beacon_coords[b.beaconId].coords[0],
      lat: beacon_coords[b.beaconId].coords[1],
      floorNum: beacon_coords[b.beaconId].floorNum,
    }
  })
  return processed
}

function weightedAvgTrilaterate(beacons: any) {
  // Filter out beacons without valid coordinates
  const validBeacons = beacons.filter((b: any) => b?.long || b?.lat || b?.floorNum)

  if (validBeacons.length < 3) {
    console.error('Not enough valid beacons for trilateration.')
    return null
  }

  // Sort beacons by strongest RSSI (closest)
  validBeacons.sort((a: any, b: any) => b.rssi - a.rssi)

  // Use the top N beacons (at least 3)
  const selectedBeacons = validBeacons.slice(0, Math.min(validBeacons.length, 5))

  return trilaterateGeneric(selectedBeacons)
}

function trilaterateGeneric(beacons: any) {
  console.log(`Running trilateration with ${beacons.length} beacons...`)

  // RSSI to distance conversion parameters
  const RSSI_REF = -60 // Reference RSSI at 1 meter
  const N = 2 // Propagation constant (environmental factor)

  const calculateDistance = (rssi: any) => Math.pow(10, (RSSI_REF - rssi) / (10 * N))

  // Compute distances for each beacon
  const distances = beacons.map((b: any) => calculateDistance(b.rssi))

  // Compute weighted averages
  let sumX = 0,
    sumY = 0,
    sumZ = 0,
    sumW = 0

  for (let i = 0; i < beacons.length; i++) {
    const { long, lat, floorNum } = beacons[i]
    const weight = 1 / distances[i] // Inverse of distance for weighting

    sumX += long * weight
    sumY += lat * weight
    sumZ += floorNum * weight
    sumW += weight
  }

  return {
    lat: sumY / sumW,
    long: sumX / sumW,
    floorNum: sumZ / sumW,
  }
}

function floorSelection() {
  return
}

// const processed = preProcess(alertEventBedroom)
// console.log(weightedAvgTrilaterate(processed))

type Props = {
  alertSession: AlertSession
}

const VenueMap2 = ({ alertSession }: Props) => {
  const [initialized, setInitialized] = useState(false)
  const [mapData, setMapData] = useState<any>()
  const [mapView, setMapView] = useState<any>(null)
  const [floor, setFloor] = useState<any>()
  const [floors, setFloors] = useState<any>([])
  const [clickedCoord, setClickedCoord] = useState<any>(null)
  const [lastLocation, setLastLocation] = useState<any>(null)
  const { selectedOrg } = useGlobal()

  useEffect(() => {
    console.log('~tri-lastBeacons', alertSession.lastBeacons)
    const processed = preProcess(alertSession.lastBeacons)
    console.log('~tri:', weightedAvgTrilaterate(processed))
  }, [alertSession])

  useEffect(() => {
    async function init() {
      console.log('Running mi init')
      let options: any = null
      if (selectedOrg?.mappedin) {
        const { mapId, key, secret } = selectedOrg.mappedin
        options = { mapId, key, secret }
      }

      if (!options) {
        console.log('~no options - returning', options)
        return
      }

      const mapData = await getMapData(options)

      const floors = mapData.getByType('floor')
      console.log('~floors:', floors)

      const mapView = await show3dMap(document.getElementById('mappedin-map') as HTMLDivElement, mapData)
      // Set the floor to Floor.id 'm_987654321'. first floor
      console.log('mi-floors:', floors)
      floors.forEach(f => {})

      // Sets the floor to match the last beacon location in alert session
      let floor = floors.filter(f => f.name == alertSession?.lastLocation?.floor)[0]
      // Sets floor to match trilateration floor prediction and uses temp floor mapping object
      const processed = preProcess(alertSession.lastBeacons)
      const location = weightedAvgTrilaterate(processed)
      const floorNum = Math.round(location?.floorNum || -1)
      let floorTri = floors.filter(f => {
        if (alertSession.orgId === '6e5a4fb3-d880-47c3-8be5-5b830ded5fdc') return f.id === jeffFloors[floorNum]
        else if (alertSession.orgId === '0f96d715-d982-405c-adc1-4a23065df912') return f.id === rileysFloors[floorNum]
      })

      if (floorTri) mapView.setFloor(floorTri[0])

      const XLocation = {
        floorId: jeffFloors[floorNum],
        latitude: location?.lat,
        longitude: location?.long,
        __type: 'coordinate',
      } as any
      // Add marker for trilateration
      mapView.Markers.add(XLocation, '<div style="font-size: 3rem; color: red;">x</div>', {
        interactive: true,
        anchor: 'center',
        rank: 'always-visible',
      })

      const markerTemplate = `
          <div style="display: grid; place-content: center; background-color: black; border-radius: 100px; width: 22px; height: 22px;">   
            <div class="marker" style="display: grid; place-content: center;">
                <img src="/Beacons_Icon.svg" alt="Marker Image"  />
            </div>
          </div>`

      mapView.Labels.all()
      // Set each space to be interactive.
      mapData.getByType('space').forEach(space => {
        console.log({ space })
        mapView.updateState(space, {
          interactive: true,
        })
      })

      const defaultCameraPosition: TCameraTarget = {
        bearing: mapView.Camera.bearing,
        pitch: mapView.Camera.pitch,
        zoomLevel: mapView.Camera.zoomLevel,
        center: mapView.Camera.center,
      }

      // Set each space to be interactive and its hover color.
      mapData.getByType('space').forEach((space, i) => {
        mapView.updateState(space, {
          //   color: i % 2 == 1 ? 'red' : 'blue',
          interactive: true,
          hoverColor: '#D3D3D3',
        })
      })

      mapData.getByType('space').forEach((space, i) => {
        if (space.name == alertSession?.lastLocation?.room) {
          mapView.updateState(space, {
            //   color: i % 2 == 1 ? 'red' : 'blue',
            interactive: true,
            hoverColor: '#D3D3D3',
            color: PRIMARY_COLOR,
          })
        }
      })

      let focused: boolean = false

      // Act on the click event to focus on the Space that was clicked or reset the camera.
      mapView.on('click', async event => {
        console.log('clicked event:', event)
        setClickedCoord(event.coordinate)

        mapView.Camera.focusOn(event.spaces[0])
        focused = true
        // }
      })

      setLastLocation(alertSession?.lastLocation)
      setMapView(mapView)
    }

    const sameLocation =
      alertSession?.lastLocation?.floor == lastLocation?.floor && alertSession?.lastLocation?.room == lastLocation?.room

    if (sameLocation) {
      return
    }

    init()
    return () => {}
  }, [initialized, alertSession]) // Include venueOptions in the dependency array

  return <div style={{ width: '800px', height: '800px' }} id="mappedin-map" />
}

export default VenueMap2
